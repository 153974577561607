<template>
  <div class="d-flex justify-content-center">
    <div class="album-container">
      <div class="row">
        <div class="col-3 my-auto">
          <!-- <img class="img-fluid"
               :src="album.thumbnail"
               alt="album-cover" /> -->
          <img class="img-fluid"
               :src="album.album_thumbnail"
               alt="album-cover" />
        </div>
        <div class="col-9 my-auto">
          <h3>{{ album.album_name }}</h3>
          <b-button class="mx-2"
                  variant="outline-success"
                  @click="addSong" v-if="album">
            <i class="fas fa-plus"></i> Add Song
          </b-button>
          <b-button class="mx-2"
                    variant="outline-success"
                    @click="editAlbum"
                    v-if="album">
            <i class="fas fa-edit"></i> Edit Album
          </b-button>
          <b-button class="mx-2"
                    variant="outline-danger"
                    @click="deleteAlbum"
                    v-if="album">
            <i class="far fa-trash-alt"></i> Delete Album
          </b-button>
          <b-button class="mx-2"
                    variant="outline-primary"
                    @click="notify"
                    v-if="album">
            <i class="far fa-bell"></i> Notify
          </b-button>
          <br>
          <div class="mt-2 ml-2">
            Order No:
            <b-form-select class="order-selection"
                           :options="orderOptions"
                           v-model="order"
                           @change="changeOrder"
                           size="sm">
            </b-form-select>
          </div>
        </div>
      </div>
      <song-list :songs="albumSongs"
                 :albumId="albumId" />
    </div>

    <!-- Modal -->
    <modals-container/>

    <!-- Dialog -->
    <v-dialog/>
  </div>
</template>

<script>
  const AddSong = () => import('./AddSong.vue');
  const EditAlbum = () => import('./EditAlbum.vue');
  const SongList = () => import('./SongList.vue');
  import { mapGetters, mapActions } from 'vuex';

  export default {
    name  : 'AlbumCard',
    props : {
      albumId    : Number,
      albumTotal : Number,
    },
    data() {
      return {
        order : null,
      }
    },
    computed : {
      ...mapGetters({
        album      : 'media/album',
        albumSongs : 'media/albumSongs',
      }),
      orderOptions() {
        const options = [{
          value    : null,
          text     : 'Select Order',
          disabled : true,
        }];
        for (let i = 1; i <= this.albumTotal; i++) {
          options.push({
            value : i,
            text  : i,
          });
        }
        return options;
      },
    },
    components : {
      SongList,
    },
    watch : {
      albumId() {
        this.getAlbum(this.albumId);
      },
      album(val) {
        this.order = val.order_no;
      },
    },
    methods : {
      ...mapActions({
        'getAlbum' : 'media/getAlbum',
      }),

      /**
       * Show Add Song Modal
       */
      addSong() {
        this.$modal.show(
          AddSong,
          { album : this.album },
          { height : 'auto', adaptive : true }
        );
      },

      /**
       * Show Edit Album Modal
       */
      editAlbum() {
        this.$modal.show(
          EditAlbum,
          { album : this.album },
          { height : 'auto', adaptive : true },
          // eslint-disable-next-line no-unused-vars
          { 'before-close' : async (event) => {
            this.getAlbum(this.album.mobile_album_id);
            const albumData = {
              id        : this.album.mobile_album_id,
              albumName : this.album.album_name,
              thumb     : this.album.album_thumbnail,
              orderNo   : this.album.order_no,
            };
            this.$emit('update-selected-album', albumData);
          }},
        );
      },

      /**
       * Delete Album
       */
      deleteAlbum() {
        this.$modal.show('dialog', {
          title : 'Please confirm:',
          text  : `Are you sure you want to delete ` +
            `<b>${this.album.album_name}</b>?`,
          buttons : [
            {
              title   : 'Yes',
              handler : () => {
                this.$http.delete('/api/album', {
                  data : {
                    id : this.album.mobile_album_id,
                  },
                }).then(() => {
                  this.$notify({
                    group : 'notif',
                    type  : 'success',
                    title : 'Success!',
                    text  : 'Deleted ' + this.album.album_name +
                      ' successfully',
                  });
                  this.$emit('delete-album-update');
                  this.$modal.hide('dialog');
                }).catch(() => {
                  this.$notify({
                    group : 'notif',
                    type  : 'error',
                    title : 'Failed',
                    text  : 'Oops! Something went wrong!',
                  });
                });
              },
            },
            { title : 'No' },
          ],
        });
      },

      /**
       * Send Notification
       */
      notify() {
        this.$modal.show('dialog', {
          title : 'Send Notification',
          text  : `Send New Music Notification for ` +
            `<b>${this.album.album_name}</b>?`,
          buttons : [ {
            title   : 'Yes',
            handler : () => {
              this.$http.post('/api/notification/admin/new_music', {
                mobileAlbumId : this.album.mobile_album_id,
                // albumName: "random album",
                // mobileAlbumThumbnail: "cdvsrver"
              }).then(() => {
                this.$notify({
                  group : 'notif',
                  type  : 'success',
                  title : 'Success!',
                  text  : 'Notification sent.',
                });
                this.$modal.hide('dialog');
              }).catch(() => {
                this.$notify({
                  group : 'notif',
                  type  : 'error',
                  title : 'Failed',
                  text  : 'Oops! Something went wrong!',
                });
              });
            },
          }, {
            title : 'No',
          } ],
        });
      },

      /**
       * Change mobile album order
       * @param data
       */
      changeOrder(data) {
        this.$http.post('api/setAlbumOrderNumber', {
          mobileAlbumId : this.albumId,
          orderNo       : data,
        }).then((res) => {
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success',
            text  : 'Order successfully updated',
          });
          const albumData = {
            id        : res.data.album.mobile_album_id,
            albumName : res.data.album.album_name,
            thumb     : res.data.album.album_thumbnail,
            orderNo   : res.data.album.order_no,
          };
          this.$emit('update-selected-album', albumData);
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : 'Oops! Something went wrong! Please try again.',
          });
        });
      },
    },
    mounted() {
      this.getAlbum(this.albumId);
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/admin/mobile_music/album-card";
</style>